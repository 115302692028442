$(document).ready(function(){


  var ROOT_PATH = '/data/';
  var typeOfForecast = $('#chart-table').attr('data-ftype'); // wind vs surf
  var chartOptions;
  var forecastData;

  // Build the chart
  if($('#chart-table').length) {
    var nsID = $('#chart-table').attr('data-nid');
    var dom = document.getElementById('chart-container');
    var myChart = echarts.init(dom, null, {
      renderer: 'canvas',
      useDirtyRect: false
    });

    function initChartOptions() {
      chartOptions = {
        grid: {
          top: 33,
          bottom: 40,
          left: 40,
          right: 10,
        },
        tooltip: {
          trigger: 'axis',
          formatter: function (params) {
            // console.log(params);
            var res = '';
            if(params.length == 1){
              // this data can be observation or forecast
              if(params[0]['seriesName'] == 'Observation'){
                res = '<span class="measured">Measured</span>: ';
              }else{
                res = '<span class="forecasted">Predicted</span>: ';
              }
              if(typeOfForecast == 'wind'){
                var knots = params[0]['value'][1].toFixed(2)*1.94384449;
                measuredVal = knots.toFixed(0) +' kt'
              }else{
                measuredVal = params[0]['value'][1].toFixed(1) +' m';
              }
              res += measuredVal + '<br><span>&nbsp;</span>';



            }else{
              // params[0] == forecast
              // params[1] == observation
              if(typeOfForecast == 'wind'){
                var knotsForecast = params[0]['value'][1].toFixed(2)*1.94384449;
                var knotsObservation = params[1]['value'][1].toFixed(2)*1.94384449;
                measuredVal = '<span class="measured">Measured</span>: ' + knotsObservation.toFixed(0) +' kt<br/><span class="forecasted">Predicted</span>: ' + knotsForecast.toFixed(0) + ' kt';
              }else{
                measuredVal = '<span class="measured">Measured</span>: ' + params[1]['value'][1].toFixed(1) +' m<br/><span class="forecasted">Predicted</span>: ' + params[0]['value'][1].toFixed(1) + ' m';
              }
              res += measuredVal;
            }

            //return `${params[0]['axisValueLabel']}&nbsp;&nbsp;<br /><span class="forecasted">Predicted</span>: ${predVal}<br />${p1}<br />`;
            return `${params[0]['axisValueLabel']}&nbsp;&nbsp;<br />${res}`;

          },
          textStyle: {color:'black'}
        },

        xAxis: {
          type: 'time',
          nameLocation: 'middle',
          axisLabel: {
            formatter: '{dd}.{MM}',
            color:'black'
          },
          axisLine: {
            show:false
          },
          axisTick: {
            show:false
          },
          axisPointer: {
            label: {
              formatter: function (params) {
                var date = new Date(params.value);
                return (
                  days[date.getDay()] + ' ' + date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear() + ' ' + date.getHours() + ':00'
                );
              },
            },
            type:'none',
          },
        },
        yAxis: {
          type:'value',
          axisLabel: {
            color:'black'
          },
          splitLine: {
            show: false
          }
        },
        series: [
          {
            name: 'Forecast',
            type: 'line',
            lineStyle: {
              width:1,
              color: '#d5b4e4',
            },
            symbolSize:8,
            symbol:'image:///img/dot.svg',
            showSymbol: false,
            data: [],
            smooth: false
          },
          {
            name: 'Observation',
            type: 'line',
            symbolSize:8,
            symbol:'image:///img/blue.svg',
            showSymbol: false,
            data: [],
            smooth: false,
            lineStyle: {
              width:1,
              color: '#0A9A82'
            }
          }
        ]
      };
    }
    initChartOptions();
    myChart.setOption(chartOptions);


    function getForecastData(){
      // console.log('forecast: ' + typeOfForecast)
        var obsURL = 'observation.php';
        if(typeOfForecast == 'wind') {
          obsURL = 'windobservation.php';
        }
        $.get(ROOT_PATH + obsURL + '?id='+ nsID +'').done(function(data) {

          // Fill in the data
          myChart.setOption({
            series: [
              {
                // Find series by name
                name: 'Observation',
                data: data,
              }
            ]
          });

          if(typeOfForecast == 'wind') {
            myChart.setOption({
              yAxis: {
                axisLabel: {
                  formatter: function(params){
                    //console.log(params)
                    var kt = params*1.94384449;
                    return  kt.toFixed(0) + ' kt'
                  }
                }
              },
            });
          }else{
            myChart.setOption({
              yAxis: {
                axisLabel: {
                  formatter: '{value} m',
                }
              },
            });
          }
          $.get(ROOT_PATH + 'forecast.php?id='+ nsID +'').done(function(data) {
            forecastData = data;
            if(typeOfForecast != 'wind'){
              myChart.setOption({
                series: [
                  {
                    // Find series by name
                    name: 'Forecast',
                    data: data
                  }
                ]
              });
            }

            $.get(ROOT_PATH + 'waveperiodforecast.php?id='+ nsID +'').done(function(data) {
              for (var i = forecastData.length - 1; i >= 0; i--) {
                forecastData[i][3] = data[i][1];
              }
              $.get(ROOT_PATH + 'windforecast.php?id='+ nsID +'').done(function(data) {
                for (var i = forecastData.length - 1; i >= 0; i--) {
                  forecastData[i][4] = data[i][1];
                  if(typeOfForecast == 'wind'){
                    myChart.setOption({
                      series: [
                        {
                          // Find series by name
                          name: 'Forecast',
                          data: data
                        }
                      ]
                    });
                  }
                }
                $.get(ROOT_PATH + 'winddirectionforecast.php?id='+ nsID +'').done(function(data) {
                  for (var i = forecastData.length - 1; i >= 0; i--) {
                    forecastData[i][5] = data[i][1];
                  }
                  getTides();
                })

              })
            })



          });
        });

    }
    getForecastData();


    window.addEventListener('resize', myChart.resize);

  }


  var rows = '';
  var tides = [];

  function getTides(){
    var dateRange = [];
    var days = _.groupBy(forecastData, item => new Date(item[0]).getDate());
    days = _.sortBy(days);
    for (const key in days) {
      dateRange.push(renderTime(days[key][0][0],'tide'))
    }
    dateRange = _.sortBy(dateRange);
    // console.log(dateRange)
    $.get(ROOT_PATH + 'tides.php?start_date='+dateRange[0]+'&end_date='+dateRange[dateRange.length-1]).done(function(data) {
      // console.log(data);
      tides = data;
      // when finished, build the tables
      buildForecastTable(days,tides);
      // set today's tides

      var tide = renderTide(Date.now());
      //console.log(tide)
      $('#ht').html(tide.ht);
      $('#lt').html(tide.lt);
      // console.log(htoday);
    })

  }

  function renderTide(date){
    //console.log('renderTide',date);
    var ttoday = _.filter(tides, function(n) {
      return n.tide_date == renderTime(date,'tide');
    });
    var ht = '';
    var lt = '';

    for (var i = 0; i <= ttoday.length - 1; i++) {
      //console.log(ttoday[i].hwtime,ttoday[i].lwtime);
      if (ttoday[i].hwtime != '--.--') {
        ht += '<div>' + luxon.DateTime.fromISO(ttoday[i].hwtime).plus({hours:2}).toFormat('HH:mm') + '</div>';
      }
      if (ttoday[i].lwtime != '--.--') {
        lt += '<div>' + luxon.DateTime.fromISO(ttoday[i].lwtime).plus({hours:2}).toFormat('HH:mm') + '</div>';
      }
    }
    return { ht:ht,lt:lt };
  }

  function buildForecastTable(days,tides){

    rows = '';
    for (const key in days) {
        // console.log(`${key}: ${days[key]}`);
      var day = new Date(days[key][0][0]);
      var today = new Date().setHours(0,0,0,0);
      //console.log(day,today);

      if(day >= today){
        var dayStr = '<div class="day">';
        dayStr += '<div class="label">'+ renderTime(days[key][0][0],'day') +'</div>';
        var tide = renderTide(renderTime(days[key][0][0],'tide'));
        dayStr += '<div class="t-tide"><div class="hl"><div>High tide</div>'+tide.ht + '</div><div class="hl"><div>Low tide</div> ' + tide.lt + '</div></div>';

        dayStr += '<div class="col-labels"><div class="time-label">Time</div><div class="waves-label">Waves</div><div class="wind-label">Wind</div></div>';

        var tmpClass = '';
        for (var i = 0; i < days[key].length; i++) {
          // console.log('days')
          var newDayHasForcast = false;
          var tclass = "";
          if(!lessThanOneHourAgo(days[key][i][0])){
            tclass="past";
          }
          if(tclass == '' && tmpClass=="past"){
            tclass= 'coming';
          }
          var shclass = '';
          if(days[key][i][2] > 49 && days[key][i][1] > 0.49 && typeOfForecast != 'wind'){
            shclass = 'yeah';
          }
          tmpClass = tclass;
          var timeEl = '<div class="time">'+ renderTime(days[key][i][0],'time') + '</div>';



          var waveHeightEl = '<div class="wave-height">'+ days[key][i][1].toFixed(1) + '<span class="unit">m</span></div>';
          var wavePeriodEl = '<div class="wave-period">'+ days[key][i][3].toFixed(0) + '<span class="unit">s</span></div>';
          var significantHeightEl = '<div class="sign-height" title="% due to swell">'+ days[key][i][2].toFixed(0) + '%</div>';
          var waveContainerEl = '<div class="wave-info">'+ waveHeightEl + wavePeriodEl + significantHeightEl +'</div>';

          var knots = days[key][i][4].toFixed(2)*1.94384449;
          var windSpeedEl = '<div class="wind-speed">'+ knots.toFixed(0) + '<span class="unit">kt</span></div>';
          var wdVal = days[key][i][5];
          var arrow = "<div class='arrow' style='transform:rotate("+wdVal+"deg)' title='"+ wdVal +"° "+ degToCompass(wdVal) +"'>↓</div>";
          var windDirectionEl = '<div class="wind-direction"><div class="wd-holder">'+ arrow +'</div></div>';
          var windContainerEl = '<div class="wind-info">'+ windDirectionEl + windSpeedEl +'</div>';

          if(typeOfForecast == 'wind') {
            $('#ws').html(); // why?
          }else{

          }



          var row = '<div class="row '+ tclass +' '+ shclass +'">' + timeEl + waveContainerEl + windContainerEl + '</div>';
          if(renderTime(days[key][i][0],'time') != '01:00' && renderTime(days[key][i][0],'time') != '02:00'){
            newDayHasForcast = true;
            dayStr += row;
          }

          // console.log(formatDate(forecast[i][0]),forecast[i][2])
        }


        dayStr += '<div>&nbsp;</div></div>';

        if (newDayHasForcast) {
          rows += dayStr;
        }


      }
    }

    var htmlObject = document.createElement('div');
    htmlObject.id = 'days';
    htmlObject.innerHTML = rows;
    $('#table').html('');
    table.append(htmlObject);

  }




  $('.switch').click(function(){
    var currPredType = $(this).attr('data-current-prediction-type');
    if (currPredType == 'wind') {
      // load the waves
      typeOfForecast = 'waves';
      $('#pred-type-header').html('Significant wave height & wind speed <sup>2</sup>');
      $(this).html('kite mode');
      $('body').removeClass('wind');
    }else{
      // load the wind
      typeOfForecast = 'wind';
      $('#pred-type-header').html('Wind speed & significant wave height<sup>2</sup>');
      $(this).html('surf mode');
      $('body').addClass('wind');
    }
    $(this).attr('data-current-prediction-type',typeOfForecast);

    getForecastData();
  });

  // Get current data
  var tk = '';
  var wT = $('#wt').attr('data-wt');
  var wH = $('#wh').attr('data-wh');
  var hW = $('#hw').attr('data-hw');
  var wP = $('#wp').attr('data-wp');
  var wS = $('#ws').attr('data-ws');
  var wD = $('#wd').attr('data-wd');
  var wvD = $('#wvd').attr('data-wvd');

  $.get(ROOT_PATH + 'meetnet.php').done(function(data) {
    tk = data.access_token;
    $.get(ROOT_PATH + 'meetnet.php?action=cd&t='+ tk).done(function(cdata) {
      // console.log(cdata);
      var seaTemp = _.result(_.find(cdata, function(chr) {
        return chr.ID == wT;
      }),'Value');
      $('#wt').html(seaTemp + '°C');

      var waveHeight = _.result(_.find(cdata, function(chr) {
        return chr.ID == wH;
      }),'Value');
      var whM = waveHeight/100;
      $('#wh').html(whM.toFixed(1) + '&nbsp;m');

      var highestWaves = _.result(_.find(cdata, function(chr) {
        return chr.ID == hW;
      }),'Value');
      var hwM = highestWaves/100;
      $('#hw').html(hwM.toFixed(1) + '&nbsp;m');

      var wavePeriod = _.result(_.find(cdata, function(chr) {
        return chr.ID == wP;
      }),'Value');
      $('#wp').html(wavePeriod.toFixed(1) + '&nbsp;s');

      var waveDirection = _.result(_.find(cdata, function(chr) {
        return chr.ID == wvD;
      }),'Value');

      if (waveDirection != undefined) {
        var arrow = "<div style='transform:rotate("+waveDirection+"deg)'>↓</div>";
        $('#wvd').html(arrow + '&nbsp;' + waveDirection + '° ' + degToCompass(waveDirection));
      }else{
        $('#wvdr').hide();
      }

      var windSpeed = _.result(_.find(cdata, function(chr) {
        return chr.ID == wS;
      }),'Value');
      var knots = windSpeed*1.94384449;
      $('#ws').html(knots.toFixed(0) + '&nbsp;kt');

      var windDirection = _.result(_.find(cdata, function(chr) {
        return chr.ID == wD;
      }),'Value');
      var arrow = "<div style='transform:rotate("+windDirection+"deg)'>↓</div>";
      $('#wd').html(arrow + '&nbsp;' + windDirection + '° ' + degToCompass(windDirection));

      var mtime = _.result(_.find(cdata, function(chr) {
        return chr.ID == wP;
      }),'Timestamp');
      var ctime= luxon.DateTime.fromISO(mtime).toFormat('H:mm');
      $('#mtime').html('Last update: '+ctime);

      // "OSTGH1", 10% hoogste golven
      // "OSTGHA", Golfhoogte
      // OSTGTZ, gemiddelde golfperiode
      // OSTTZW, temp zeewater
    })

  })




  function sunSetRise(){
    var lat = $('#sunrise').attr('data-lat');
    var lng = $('#sunrise').attr('data-lng');

    $.get('https://api.sunrise-sunset.org/json?lat='+ lat +'&lng='+ lng +'&formatted=0').done(function(data) {
      // console.log(data.results,data.results.sunrise);
      $('#sunrise').html(luxon.DateTime.fromISO(data.results.sunrise).toFormat('HH:mm'));
      $('#sunset').html(luxon.DateTime.fromISO(data.results.sunset).toFormat('HH:mm'))
    })
  }
  sunSetRise();



  // Small waves
  $('#locations').change(function(){
    window.location.replace($(this).val());
  })
  $(".video").fitVids({ customSelector: "iframe[src^='https://g0.ipcamlive.com']"});

});

